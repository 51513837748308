import { DocumentNode, OperationVariables, QueryHookOptions, TypedDocumentNode, useLazyQuery } from '@apollo/client';
import { notification } from 'antd';

export const useLazyQueryErrorNotification = <Q = any, V extends OperationVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<Q, V>,
  options?: QueryHookOptions<Q, V> | undefined,
) => {
  const showErrorNotification = (message: string, description: string) => {
    notification.error({
      message,
      description,
      placement: 'topRight',
    });
  };

  return useLazyQuery<Q, V>(query, {
    ...options,
    onError: (e) => {
      e.graphQLErrors.map((graphQLError: { message: string; }) =>
        showErrorNotification('Error completing request', graphQLError.message),
      );
    },
  });
};

import { Button, Modal, Form, Row, Col, Select, DatePicker, Input, Switch } from 'antd';
import React from 'react';
import moment from 'moment';
import { Discount } from 'src/graphql/queries/get-all-discounts';
import { DiscountTypeEnum } from 'src/graphql/generated/types';
import { useCreateDiscountMutation } from 'src/graphql/mutations/create-discount';
import { useUpdateDiscountMutation } from 'src/graphql/mutations/update-discount';

const { Option } = Select;

export interface DiscountsProps {
  show?: boolean;
  isEdit?: boolean;
  onClose?: () => void;
  onDiscountActionCompleted?: (discount: Discount) => void;
  initialDiscount?: Discount;
}

export const DiscountModal: React.FC<DiscountsProps> = ({
  show,
  isEdit,
  onClose,
  onDiscountActionCompleted,
  initialDiscount,
}) => {
  const [form] = Form.useForm();
  const [createDiscountMutation, { loading: createLoading }] = useCreateDiscountMutation();
  const [updateDiscountMutation, { loading: updateLoading }] = useUpdateDiscountMutation();

  const handleSave = async (payload: any) => {
    const discountData: any = {
      startDate: payload.startDate.format('YYYY-MM-DD'),
      endDate: payload.endDate.format('YYYY-MM-DD'),
      isActive: payload.isActive,
      isOnline: payload.isOnline,
      isOffline: payload.isOffline,
      code: payload.code,
      description: payload.description,
    };

    if (isEdit && initialDiscount) {
      const response = await updateDiscountMutation({
        variables: {
          discountId: initialDiscount.id,
          input: {
            isActive: discountData.isActive,
            isOnline: discountData.isOnline,
            isOffline: discountData.isOffline,
          },
        },
      });

      if (response.data && onDiscountActionCompleted) {
        onDiscountActionCompleted(response.data.updateDiscount);
        form.resetFields();
      }
    } else {
      const response = await createDiscountMutation({
        variables: {
          input: {
            type: payload.type,
            amount: Number(payload.amount),
            ...discountData,
          },
        },
      });

      if (response.data && onDiscountActionCompleted) {
        onDiscountActionCompleted(response.data.createDiscount);
        form.resetFields();
      }
    }
  };

  const handleCancel = () => {
    form.resetFields();
    if (onClose) {
      onClose();
    }
  };

  const initialFormValues = initialDiscount
    ? {
      ...initialDiscount,
      startDate: moment(initialDiscount.startDate),
      endDate: moment(initialDiscount.endDate),
      isActive: initialDiscount.isActive,
      isOnline: initialDiscount.isOnline,
      isOffline: initialDiscount.isOffline,
    }
    : {
      startDate: moment(),
      endDate: moment(),
      isActive: true,
      isOnline: true,
      isOffline: true,
    };

  return (
    <Modal
      title={isEdit ? 'Update Discount' : 'Add Discount'}
      open={show}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => form.submit()}
          loading={isEdit ? updateLoading : createLoading}
        >
          Save
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={initialFormValues}
        onFinish={handleSave}
        autoComplete="on"
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Discount Type"
              name="type"
              rules={[{ required: true, message: 'Please select a discount type' }]}
            >
              <Select placeholder="Select type" disabled={isEdit}>
                <Option value={DiscountTypeEnum.Percentage}>Percentage</Option>
                <Option value={DiscountTypeEnum.Flat}>Fixed Amount</Option>
                <Option value={DiscountTypeEnum.PromoCode}>Promo Code</Option>
                <Option value={DiscountTypeEnum.Seasonal}>Seasonal</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Amount"
              name="amount"
              rules={[{ required: true, message: 'Please enter the discount amount' }]}
            >
              <Input type="number" placeholder="Enter amount" disabled={isEdit} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Start Date"
              name="startDate"
              rules={[{ required: true, message: 'Please select the start date' }]}
            >
              <DatePicker placeholder="Select start date" format="YYYY-MM-DD" disabled={isEdit} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="End Date"
              name="endDate"
              rules={[{ required: true, message: 'Please select the end date' }]}
            >
              <DatePicker placeholder="Select end date" format="YYYY-MM-DD" disabled={isEdit} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label="Discount Code"
          name="code"
          rules={[{ required: true, message: 'Please enter the discount code' }]}
        >
          <Input placeholder="Enter discount code" disabled={isEdit} />
        </Form.Item>

        <Form.Item label="Description" name="description">
          <Input.TextArea rows={3} placeholder="Enter description" disabled={isEdit} />
        </Form.Item>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label="Active" name="isActive" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label="Online" name="isOnline" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label="Offline" name="isOffline" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

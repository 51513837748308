import { LazyQueryResultTuple, gql } from '@apollo/client';
import {
  GetPassengerByPhoneNumberQuery,
  GetPassengerByPhoneNumberQueryVariables,
} from 'src/graphql/queries/__generated__/find-passenger-by-phone-number.generated';
import { useLazyQueryErrorNotification } from 'src/graphql/hooks/useLazyQueryErrorNotification';

const GET_PASSENGER_BY_PHONE_NUMBER_QUERY = gql`
  query GetPassengerByPhoneNumber($phone: String!) {
    getPassengerByPhone(phone: $phone) {
      id
      fullName
      gender
      nextKinFirstName
      nextKinLastName
      nextKinPhone
      phone
    }
  }
`;

export const useLazyPassengerByPhoneNumberQuery = (): LazyQueryResultTuple<
  GetPassengerByPhoneNumberQuery,
  GetPassengerByPhoneNumberQueryVariables
> =>
  useLazyQueryErrorNotification<GetPassengerByPhoneNumberQuery, GetPassengerByPhoneNumberQueryVariables>(
    GET_PASSENGER_BY_PHONE_NUMBER_QUERY,
    { fetchPolicy: 'network-only' },
  );

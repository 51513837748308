export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  _Any: { input: any; output: any; }
  federation__FieldSet: { input: any; output: any; }
  link__Import: { input: any; output: any; }
};

export type ActivateDeactivateInput = {
  /** The activate boolean to determine if the discount is activated or not */
  activate: Scalars['Boolean']['input'];
};

export type Address = {
  __typename?: 'Address';
  country: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  lineOne: Scalars['String']['output'];
  lineTwo?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type AvailableTravelSchedule = {
  __typename?: 'AvailableTravelSchedule';
  arrivals?: Maybe<Array<TravelSchedule>>;
  departures: Array<TravelSchedule>;
};

export type Booking = {
  __typename?: 'Booking';
  authorizationUrl?: Maybe<Scalars['String']['output']>;
  passengers: Array<Passenger>;
  reservation: Reservation;
  tickets: Array<Ticket>;
  totalFare: Scalars['Float']['output'];
  transaction: Transaction;
};

export type BookingPrice = {
  __typename?: 'BookingPrice';
  passengers: Array<PassengerPrice>;
  schedule: PriceBookingSchedule;
  totalFare: Scalars['Float']['output'];
};

export type CompleteBooking = {
  __typename?: 'CompleteBooking';
  reservation: Reservation;
  tickets: Array<Ticket>;
  transaction: Transaction;
};

export type CreateDiscountInput = {
  /** The amount for the discount */
  amount: Scalars['Float']['input'];
  /** Promo code (if applicable). */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Description of the discount. */
  description: Scalars['String']['input'];
  /** The end date of the discount (for seasonal or promo-based discounts) */
  endDate?: InputMaybe<Scalars['String']['input']>;
  /** Boolean flag to check if the discount is currently active. */
  isActive: Scalars['Boolean']['input'];
  /** Boolean flag to check if the discount is only applicable to offline platform */
  isOffline: Scalars['Boolean']['input'];
  /** Boolean flag to check if the discount is only applicable to online platfrom (Mobile or Web) */
  isOnline: Scalars['Boolean']['input'];
  /** The start date of the discount (for seasonal or promo-based discounts) */
  startDate?: InputMaybe<Scalars['String']['input']>;
  /** Discount type */
  type: DiscountTypeEnum;
};

export type CreatePassengersInput = {
  /** The Id of the  branch the passenger is departing from */
  branchId: Scalars['Float']['input'];
  /** Passengers email address */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Passengers full name */
  fullName: Scalars['String']['input'];
  /** Passengers gender */
  gender: Scalars['String']['input'];
  /** Indicates passenger as primary passenger */
  isPrimaryPassenger: Scalars['Boolean']['input'];
  /** The next of kins first name */
  nextKinFirstName: Scalars['String']['input'];
  /** The next of kins last name */
  nextKinLastName: Scalars['String']['input'];
  /** The next of kins phone number */
  nextKinPhone: Scalars['String']['input'];
  /** Passengers phone number */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** The Id of the route for the passenger */
  routeId: Scalars['Float']['input'];
  /** Seat selected for the passenger */
  seatId?: InputMaybe<Scalars['Float']['input']>;
  /** Passengers  title */
  title: Scalars['String']['input'];
  /** The Id of the scehdeule for the passenger */
  travelScheduleId: Scalars['Float']['input'];
  /** The Id for the trip being reserved */
  tripId: Scalars['Float']['input'];
  /** Passenger type */
  type: Scalars['String']['input'];
};

export type CreateReservationInput = {
  /** List of passengers in the booking */
  passengers: Array<CreatePassengersInput>;
};

export type CreateRouteFareOverrideInput = {
  /** The currency price */
  currency: Scalars['ID']['input'];
  /** The first name of the user */
  fare: Scalars['Float']['input'];
  /** The route Id */
  routeId: Scalars['ID']['input'];
  /** The vehicle class Id */
  vehicleClassId: Scalars['ID']['input'];
};

export type CreateTripConfigInput = {
  days: Array<TripDaysEnum>;
  destinationId: Scalars['Int']['input'];
  discountIds: Array<Scalars['String']['input']>;
  frequency: Scalars['Int']['input'];
  routeGroupId: Scalars['Int']['input'];
  seatMapId: Scalars['Int']['input'];
  time: Scalars['String']['input'];
  vehicleClassId: Scalars['Int']['input'];
};

export type CreateTripInput = {
  /** Date of the trip */
  date: Scalars['String']['input'];
  /** Destination of the trip */
  destinationId: Scalars['Float']['input'];
  /** Driver ID used for the trip */
  driverId?: InputMaybe<Scalars['Float']['input']>;
  /** Expected Checkin Time for the trip */
  expectedCheckinTime: Scalars['String']['input'];
  /** Maximum capacity of passengers, mostly for trips that are not checked in immediately */
  maximumCapacity: Scalars['Float']['input'];
  /** Route Group of the trip */
  routeGroupId: Scalars['Float']['input'];
  /** Source Seat Map for the trip */
  sourceSeatMapId?: InputMaybe<Scalars['Float']['input']>;
  /** Time of the trip */
  time: Scalars['String']['input'];
  /** Vehicle Class ID used for the trip */
  vehicleClassId: Scalars['Float']['input'];
  /** Vehicle ID used for the trip */
  vehicleId?: InputMaybe<Scalars['Float']['input']>;
  /** Virtual Vehicle number used for the trip */
  virtualVehicleNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CreateUserInput = {
  /** The branches of the user */
  branchIds: Array<Scalars['Float']['input']>;
  /** The driver license number of the user */
  driversLicenseNumber?: InputMaybe<Scalars['String']['input']>;
  /** The email address of the user */
  email: Scalars['String']['input'];
  /** The first name of the user */
  firstName: Scalars['String']['input'];
  /** The last name of the user */
  lastName: Scalars['String']['input'];
  /** The password of the user */
  password: Scalars['String']['input'];
  /** The permissions of the user */
  permissions: Array<Scalars['String']['input']>;
  /** The phone number of the user */
  phone: Scalars['String']['input'];
  /** The roles of the user */
  roles: Array<Scalars['String']['input']>;
};

export type Destination = {
  __typename?: 'Destination';
  code: Scalars['String']['output'];
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  state?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type Discount = {
  __typename?: 'Discount';
  amount: Scalars['Float']['output'];
  code?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  endDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  isOffline: Scalars['Boolean']['output'];
  isOnline: Scalars['Boolean']['output'];
  startDate?: Maybe<Scalars['String']['output']>;
  type: DiscountTypeEnum;
  updatedAt: Scalars['DateTime']['output'];
};

/** The supported discount types. */
export enum DiscountTypeEnum {
  Flat = 'FLAT',
  Percentage = 'PERCENTAGE',
  PromoCode = 'PROMO_CODE',
  Seasonal = 'SEASONAL'
}

export type FindDiscountQueryInput = {
  /** Search by discount code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Search by discount active status */
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  /** Search by discount offline status */
  isOffline?: InputMaybe<Scalars['Boolean']['input']>;
  /** Search by discount online status */
  isOnline?: InputMaybe<Scalars['Boolean']['input']>;
  /** Search by discount type */
  type?: InputMaybe<Scalars['String']['input']>;
};

export type FindUsersInput = {
  search: Scalars['String']['input'];
};

export type GetAvailabilityScheduleInput = {
  /** Search data for arrival schedules if round trip */
  arrival?: InputMaybe<ItenaryInput>;
  /** Search data for departing schedules */
  depature: ItenaryInput;
};

export type ItenaryInput = {
  /** Number of adult passengers */
  adult: Scalars['String']['input'];
  /** Number of child passengers */
  child: Scalars['String']['input'];
  /** Trip date */
  date: Scalars['String']['input'];
  /** Number of infant passengers */
  infant: Scalars['String']['input'];
  /** Route for the trip */
  routeId: Scalars['String']['input'];
  /** The terminal Id */
  terminalId: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  activateDeactivate: Discount;
  checkInPassenger: Passenger;
  closeTrip: Trip;
  completeBooking: CompleteBooking;
  createDiscount: Discount;
  createOrEditReservation: Booking;
  createRouteFareOverride: RouteFareOverride;
  createTrip: Trip;
  createTripConfig: TripConfiguration;
  createUser: User;
  createVehicleClassConfig: VehicleClassConfig;
  openTrip: Trip;
  priceBooking: ReservationPricing;
  updateDiscount: Discount;
  updateRouteFareOverride: RouteFareOverride;
  updateTrip: Trip;
  updateTripSeatStatus: TripSeat;
  updateUser: User;
  updateUserBranches: Array<Terminal>;
  updateVehicleClassConfig: VehicleClassConfig;
};


export type MutationActivateDeactivateArgs = {
  discountId: Scalars['String']['input'];
  input: ActivateDeactivateInput;
};


export type MutationCheckInPassengerArgs = {
  passengerId: Scalars['Float']['input'];
  seatId: Scalars['Float']['input'];
};


export type MutationCloseTripArgs = {
  tripId: Scalars['Float']['input'];
};


export type MutationCompleteBookingArgs = {
  reservationId: Scalars['Float']['input'];
};


export type MutationCreateDiscountArgs = {
  input: CreateDiscountInput;
};


export type MutationCreateOrEditReservationArgs = {
  input: CreateReservationInput;
  provider: Scalars['String']['input'];
  reservationId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateRouteFareOverrideArgs = {
  input: CreateRouteFareOverrideInput;
};


export type MutationCreateTripArgs = {
  input: CreateTripInput;
};


export type MutationCreateTripConfigArgs = {
  input: CreateTripConfigInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationCreateVehicleClassConfigArgs = {
  charge: Scalars['Float']['input'];
  vehicleClassId: Scalars['ID']['input'];
};


export type MutationOpenTripArgs = {
  tripId: Scalars['Float']['input'];
};


export type MutationPriceBookingArgs = {
  input: PriceBookingInput;
};


export type MutationUpdateDiscountArgs = {
  discountId: Scalars['String']['input'];
  input: UpdateDiscountInput;
};


export type MutationUpdateRouteFareOverrideArgs = {
  fareOverrideId: Scalars['String']['input'];
  input: UpdateRouteFareOverrideInput;
};


export type MutationUpdateTripArgs = {
  input: UpdateTripInput;
  tripId: Scalars['Float']['input'];
};


export type MutationUpdateTripSeatStatusArgs = {
  seatId: Scalars['Float']['input'];
  status: SeatStatusEnum;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
  userId: Scalars['ID']['input'];
};


export type MutationUpdateUserBranchesArgs = {
  input: UpdateUserBranchInput;
  userId: Scalars['ID']['input'];
};


export type MutationUpdateVehicleClassConfigArgs = {
  charge: Scalars['Float']['input'];
  id: Scalars['ID']['input'];
};

export type Organization = {
  __typename?: 'Organization';
  address: Address;
  category: Scalars['String']['output'];
  name: Scalars['String']['output'];
  primaryPhone: Scalars['String']['output'];
  publicId: Scalars['String']['output'];
  secondaryPhone: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type PaginateQueryInput = {
  /** Limit for pagination */
  limit?: InputMaybe<Scalars['Float']['input']>;
  /** Offset for pagination */
  offset?: InputMaybe<Scalars['Float']['input']>;
  /** Page number */
  page: Scalars['Float']['input'];
};

export type PaginatedDiscount = {
  __typename?: 'PaginatedDiscount';
  items: Array<Discount>;
  meta: PaginationMeta;
};

export type PaginationMeta = {
  __typename?: 'PaginationMeta';
  itemCount?: Maybe<Scalars['Int']['output']>;
  nextPage: Scalars['String']['output'];
  offset?: Maybe<Scalars['Int']['output']>;
  page: Scalars['Int']['output'];
  prevPage: Scalars['String']['output'];
  totalItems?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type Passenger = {
  __typename?: 'Passenger';
  bloodGroup?: Maybe<Scalars['String']['output']>;
  branchId: Scalars['Int']['output'];
  checkInTime?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  gender?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isPrimaryPassenger: Scalars['Boolean']['output'];
  nextKinFirstName: Scalars['String']['output'];
  nextKinLastName: Scalars['String']['output'];
  nextKinPhone: Scalars['String']['output'];
  organizationId: Scalars['Int']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  pnr: Scalars['String']['output'];
  routeId: Scalars['Int']['output'];
  seat?: Maybe<TripSeat>;
  seatId?: Maybe<Scalars['String']['output']>;
  travelScheduleId: Scalars['Int']['output'];
  tripId: Scalars['Int']['output'];
  type: PassengerTypeEnum;
  updatedAt: Scalars['DateTime']['output'];
};

export type PassengerPrice = {
  __typename?: 'PassengerPrice';
  fare: Scalars['Float']['output'];
  fullName: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

/** The supported passenger types. */
export enum PassengerTypeEnum {
  Adult = 'ADULT',
  Child = 'CHILD',
  Infant = 'INFANT'
}

export type PriceBookingInput = {
  /** List of passengers in the arrival booking */
  arrival?: InputMaybe<Array<CreatePassengersInput>>;
  /** List of passengers in the departure booking */
  departure: Array<CreatePassengersInput>;
};

export type PriceBookingSchedule = {
  __typename?: 'PriceBookingSchedule';
  branchName: Scalars['String']['output'];
  date: Scalars['String']['output'];
  destinationName: Scalars['String']['output'];
  time: Scalars['String']['output'];
  vehicleClass: Scalars['String']['output'];
  vehicleNo?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  _entities: Array<Maybe<_Entity>>;
  _service: _Service;
  findDiscounts: PaginatedDiscount;
  findRouteFareOverrides: Array<RouteFareOverride>;
  findTerminal: Terminal;
  findTransactionById?: Maybe<Transaction>;
  findTripConfigurations: TripConfiguration;
  findUsers: Array<User>;
  findVehicleByRegistrationNumber: Array<Vehicle>;
  findVehicleClassConfigs: Array<VehicleClassConfig>;
  findVehicleClasses: Array<VehicleClass>;
  getAvailableSchedule: AvailableTravelSchedule;
  getBranchTripsForTransfer: Array<TripForTransfer>;
  getDiscountById?: Maybe<Discount>;
  getPassengerByPhone?: Maybe<Passenger>;
  getReservationById: Reservation;
  getReservationBySurname?: Maybe<Reservation>;
  getTerminalRoutes: Array<Route>;
  getTerminals: Array<Terminal>;
  getUser: User;
  getUserBranches: Array<Terminal>;
  tripReport: TripReport;
  tripSeatMap: TripSeatMap;
};


export type Query_EntitiesArgs = {
  representations: Array<Scalars['_Any']['input']>;
};


export type QueryFindDiscountsArgs = {
  input: FindDiscountQueryInput;
  pagination: PaginateQueryInput;
};


export type QueryFindTerminalArgs = {
  id: Scalars['String']['input'];
};


export type QueryFindTransactionByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFindUsersArgs = {
  search: FindUsersInput;
};


export type QueryFindVehicleByRegistrationNumberArgs = {
  search: Scalars['String']['input'];
};


export type QueryGetAvailableScheduleArgs = {
  input: GetAvailabilityScheduleInput;
};


export type QueryGetBranchTripsForTransferArgs = {
  branchId: Scalars['String']['input'];
  tripDate?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetDiscountByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetPassengerByPhoneArgs = {
  phone: Scalars['String']['input'];
};


export type QueryGetReservationByIdArgs = {
  reservationId: Scalars['Float']['input'];
};


export type QueryGetReservationBySurnameArgs = {
  reference?: InputMaybe<Scalars['String']['input']>;
  reservationId?: InputMaybe<Scalars['Float']['input']>;
  surname: Scalars['String']['input'];
};


export type QueryGetTerminalRoutesArgs = {
  terminalId: Scalars['String']['input'];
};


export type QueryGetUserArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryGetUserBranchesArgs = {
  userId: Scalars['Float']['input'];
};


export type QueryTripReportArgs = {
  tripId: Scalars['String']['input'];
};


export type QueryTripSeatMapArgs = {
  tripId: Scalars['String']['input'];
};

export type Reservation = {
  __typename?: 'Reservation';
  branchId: Scalars['ID']['output'];
  cancelledAt?: Maybe<Scalars['DateTime']['output']>;
  cancelledById?: Maybe<Scalars['ID']['output']>;
  contactEmail?: Maybe<Scalars['String']['output']>;
  contactName: Scalars['String']['output'];
  contactPhone: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  destinationId: Scalars['ID']['output'];
  discount?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  issuedById: Scalars['ID']['output'];
  onHoldTill?: Maybe<Scalars['DateTime']['output']>;
  reference: Scalars['String']['output'];
  schedule: TravelSchedule;
  scheduleId: Scalars['ID']['output'];
  status: ReservationStatusEnum;
  tickets: Array<Ticket>;
  transaction: Transaction;
  transactionId: Scalars['Int']['output'];
  trip: Trip;
  tripId: Scalars['ID']['output'];
};

export type ReservationPricing = {
  __typename?: 'ReservationPricing';
  arrival?: Maybe<BookingPrice>;
  departure: BookingPrice;
};

/** The supported reservation statuses. */
export enum ReservationStatusEnum {
  Cancelled = 'CANCELLED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Processed = 'PROCESSED'
}

export type Route = {
  __typename?: 'Route';
  branch: Terminal;
  branchId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  destination: Destination;
  destinationId: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  organizationId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type RouteFareOverride = {
  __typename?: 'RouteFareOverride';
  branch: Terminal;
  branchId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
  fare: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  organizationId: Scalars['Int']['output'];
  route: Route;
  routeId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  vehicleClass: VehicleClass;
  vehicleClassId: Scalars['Int']['output'];
};

export type RouteGroup = {
  __typename?: 'RouteGroup';
  branch: Terminal;
  branchId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type Seat = {
  __typename?: 'Seat';
  column: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  number: Scalars['String']['output'];
  row: Scalars['Int']['output'];
  seatMapId: Scalars['Int']['output'];
  status: SeatStatusEnum;
  updatedAt: Scalars['DateTime']['output'];
};

export type SeatMap = {
  __typename?: 'SeatMap';
  createdAt: Scalars['DateTime']['output'];
  endSeatRow: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  length: Scalars['Int']['output'];
  organizationId: Scalars['Int']['output'];
  seats: Array<Seat>;
  startSeatRow: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  width: Scalars['Int']['output'];
};

/** The supported seat status. */
export enum SeatStatusEnum {
  Available = 'AVAILABLE',
  Blocked = 'BLOCKED',
  Occupied = 'OCCUPIED',
  Reserved = 'RESERVED'
}

export type Terminal = {
  __typename?: 'Terminal';
  address: Address;
  addressId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['Int']['output'];
  publicId: Scalars['String']['output'];
  routes: Array<Route>;
  updatedAt: Scalars['DateTime']['output'];
};

export type Ticket = {
  __typename?: 'Ticket';
  branchId: Scalars['Int']['output'];
  cancelledById: Scalars['Int']['output'];
  charge: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  departureTime: Scalars['String']['output'];
  discount?: Maybe<Scalars['String']['output']>;
  groupTag: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  organizationId: Scalars['Int']['output'];
  passenger: Passenger;
  passengerId: Scalars['Int']['output'];
  routeId: Scalars['Int']['output'];
  serviceCharge: Scalars['Float']['output'];
  status: Scalars['String']['output'];
  ticketerId: Scalars['Int']['output'];
  travelScheduleId: Scalars['Int']['output'];
  tripId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type Transaction = {
  __typename?: 'Transaction';
  amount: Scalars['Float']['output'];
  branchId: Scalars['Int']['output'];
  cancelledAt: Scalars['DateTime']['output'];
  createdAt: Scalars['DateTime']['output'];
  groupTag: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  organizationId: Scalars['Int']['output'];
  passengerId: Scalars['Int']['output'];
  paymentMethod?: Maybe<Scalars['String']['output']>;
  processedAt: Scalars['DateTime']['output'];
  provider?: Maybe<Scalars['String']['output']>;
  reference: Scalars['String']['output'];
  reversedAt: Scalars['DateTime']['output'];
  serviceCharge: Scalars['Float']['output'];
  status: Scalars['String']['output'];
  ticketer?: Maybe<User>;
  ticketerId: Scalars['Int']['output'];
  totalAmount: Scalars['Float']['output'];
  travelScheduleId: Scalars['Int']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  waybillId: Scalars['Int']['output'];
};

export type TravelSchedule = {
  __typename?: 'TravelSchedule';
  branchId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  organizationId: Scalars['Int']['output'];
  route: Route;
  routeId: Scalars['Int']['output'];
  serviceCharge: Scalars['Float']['output'];
  totalFare: Scalars['Float']['output'];
  travelFare: Scalars['Float']['output'];
  trip: Trip;
  tripId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type Trip = {
  __typename?: 'Trip';
  branchId: Scalars['Int']['output'];
  closedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  date: Scalars['String']['output'];
  dateTime: Scalars['DateTime']['output'];
  destinationId: Scalars['Int']['output'];
  expectedCheckinTime?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isCheckinAvailable: Scalars['Boolean']['output'];
  maximumCapacity?: Maybe<Scalars['Int']['output']>;
  organizationId: Scalars['Int']['output'];
  routeGroup: RouteGroup;
  routeGroupId: Scalars['Int']['output'];
  routes: Array<Route>;
  seatsAvailable?: Maybe<Scalars['Int']['output']>;
  time: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  vehicleClass: VehicleClass;
  vehicleClassId: Scalars['Int']['output'];
};

export type TripConfiguration = {
  __typename?: 'TripConfiguration';
  active: Scalars['Boolean']['output'];
  branch: Terminal;
  branchId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  days: Array<TripDaysEnum>;
  destination: Destination;
  destinationId: Scalars['Int']['output'];
  discountIds?: Maybe<Array<Scalars['String']['output']>>;
  discounts: Array<Discount>;
  frequency: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  lastTripDate: Scalars['DateTime']['output'];
  organization: Scalars['Int']['output'];
  organizationId: Scalars['Int']['output'];
  routeGroup: RouteGroup;
  routeGroupId: Scalars['Int']['output'];
  seatMap: SeatMap;
  seatMapId: Scalars['Int']['output'];
  time: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  vehicleClass: VehicleClass;
  vehicleClassId: Scalars['Int']['output'];
};

/** The days trips are to be created */
export enum TripDaysEnum {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export type TripForTransfer = {
  __typename?: 'TripForTransfer';
  className: Scalars['String']['output'];
  dateTime: Scalars['DateTime']['output'];
  destinationName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type TripReport = {
  __typename?: 'TripReport';
  closingBalance: Scalars['Float']['output'];
  totalExpenses: Scalars['Float']['output'];
  totalTransactions: Scalars['Float']['output'];
};

export type TripSeat = {
  __typename?: 'TripSeat';
  column: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  number: Scalars['String']['output'];
  row: Scalars['Int']['output'];
  seatMapId: Scalars['Int']['output'];
  status: SeatStatusEnum;
  updatedAt: Scalars['DateTime']['output'];
};

export type TripSeatMap = {
  __typename?: 'TripSeatMap';
  createdAt: Scalars['DateTime']['output'];
  endSeatRow: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  length: Scalars['Int']['output'];
  organizationId: Scalars['Int']['output'];
  seats: Array<TripSeat>;
  startSeatRow: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  width: Scalars['Int']['output'];
};

export type UpdateDiscountInput = {
  /** Boolean flag to check if the discount is currently active. */
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  /** Boolean flag to check if the discount is only applicable to offline platform */
  isOffline?: InputMaybe<Scalars['Boolean']['input']>;
  /** Boolean flag to check if the discount is only applicable to online platfrom (Mobile or Web) */
  isOnline?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateRouteFareOverrideInput = {
  /** The currency price */
  currency: Scalars['ID']['input'];
  /** The first name of the user */
  fare: Scalars['Float']['input'];
  /** The route Id */
  routeId: Scalars['ID']['input'];
  /** The vehicle class Id */
  vehicleClassId: Scalars['ID']['input'];
};

export type UpdateTripInput = {
  /** Date of the trip */
  date: Scalars['String']['input'];
  /** Destination of the trip */
  destinationId: Scalars['Float']['input'];
  /** Driver ID used for the trip */
  driverId?: InputMaybe<Scalars['Float']['input']>;
  /** Expected Checkin Time for the trip */
  expectedCheckinTime: Scalars['String']['input'];
  /** Maximum capacity of passengers, mostly for trips that are not checked in immediately */
  maximumCapacity: Scalars['Float']['input'];
  /** Route Group of the trip */
  routeGroupId: Scalars['Float']['input'];
  /** Source Seat Map for the trip */
  sourceSeatMapId?: InputMaybe<Scalars['Float']['input']>;
  /** Time of the trip */
  time: Scalars['String']['input'];
  /** Vehicle Class ID used for the trip */
  vehicleClassId: Scalars['Float']['input'];
  /** Vehicle ID used for the trip */
  vehicleId?: InputMaybe<Scalars['Float']['input']>;
  /** Virtual Vehicle number used for the trip */
  virtualVehicleNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserBranchInput = {
  /** The branches of the user */
  branchIds?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type UpdateUserInput = {
  /** The first name of the user */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** The last name of the user */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** The permissions of the user */
  permissions?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The roles of the user */
  roles?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type User = {
  __typename?: 'User';
  driversLicenseNumber: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  organization: Organization;
  permissions: Array<Scalars['String']['output']>;
  phone: Scalars['String']['output'];
  publicId: Scalars['String']['output'];
  roles: Array<Scalars['String']['output']>;
  testUser: Scalars['Boolean']['output'];
  userBranches: Array<Terminal>;
};


export type Vehicle = {
  __typename?: 'Vehicle';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  make: Scalars['String']['output'];
  model: Scalars['String']['output'];
  organizationId: Scalars['Int']['output'];
  registeredNumber: Scalars['String']['output'];
  seats: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  vehicleClass: VehicleClass;
  vehicleClassId: Scalars['Int']['output'];
  vehicleNumber: Scalars['String']['output'];
};

export type VehicleClass = {
  __typename?: 'VehicleClass';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type VehicleClassConfig = {
  __typename?: 'VehicleClassConfig';
  branchId: Scalars['Int']['output'];
  charge: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  organizationId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  vehicleClass: VehicleClass;
  vehicleClassId: Scalars['Int']['output'];
};

export type _Entity = Address | Destination | Passenger | Route | RouteFareOverride | RouteGroup | Terminal | Ticket | Transaction | TravelSchedule | Trip | TripConfiguration | Vehicle | VehicleClass | VehicleClassConfig;

export type _Service = {
  __typename?: '_Service';
  sdl?: Maybe<Scalars['String']['output']>;
};

export enum Link__Purpose {
  /** `EXECUTION` features provide metadata necessary for operation execution. */
  Execution = 'EXECUTION',
  /** `SECURITY` features provide metadata necessary to securely resolve fields. */
  Security = 'SECURITY'
}

import { QueryResult, gql } from '@apollo/client';
import { useQueryErrorNotification } from 'src/graphql/hooks/useQueryErrorNotification';
import {
  FindTransactionByIdQuery,
  FindTransactionByIdQueryVariables,
} from 'src/graphql/queries/__generated__/get-transaction-by-id.generated';

const GET_TRANSACTION_BY_ID_QUERY = gql`
  query FindTransactionById($findTransactionByIdId: ID!) {
    findTransactionById(id: $findTransactionByIdId) {
        id
        organizationId
        groupTag
        amount
        totalAmount
        branchId
        ticketer {
            id
            firstName
            lastName
            phone
            email
            roles
            permissions
            userBranches {
                id
                name
            }
        }
        type
        provider
        reference
        status
        paymentMethod
        serviceCharge
        createdAt
    }
  }
`;

export const useGetTransactionById = (
  id: string,
): QueryResult<FindTransactionByIdQuery, FindTransactionByIdQueryVariables> =>
  useQueryErrorNotification<FindTransactionByIdQuery, FindTransactionByIdQueryVariables>(GET_TRANSACTION_BY_ID_QUERY, {
    variables: { findTransactionByIdId: id },
  });

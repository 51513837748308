import { MutationTuple, gql } from '@apollo/client';
import { useMutationErrorNotification } from 'src/graphql/hooks/useMutationErrorNotification';
import {
  CreateDiscountMutation,
  CreateDiscountMutationVariables,
} from 'src/graphql/mutations/__generated__/create-discount.generated';

const CREATE_DISCOUNT_MUTATION = gql`
  mutation CreateDiscount($input: CreateDiscountInput!) {
    createDiscount(input: $input) {
        id
        type
        amount
        startDate
        endDate
        code
        description
        isActive
        isOffline
        isOnline
        createdAt
        updatedAt
    } 
  }
`;

export const useCreateDiscountMutation = (): MutationTuple<CreateDiscountMutation, CreateDiscountMutationVariables> =>
  useMutationErrorNotification<CreateDiscountMutation, CreateDiscountMutationVariables>(CREATE_DISCOUNT_MUTATION);

/* eslint-disable max-len */
import { gql, QueryResult } from '@apollo/client';
import { useQueryErrorNotification } from 'src/graphql/hooks/useQueryErrorNotification';
import {
  FindVehicleByRegistrationNumberQuery,
  FindVehicleByRegistrationNumberQueryVariables,
} from 'src/graphql/queries/__generated__/find-vehicle-by-registration-number.generated';

const FIND_VEHICLE_REGISTRATION_NUMBER_QUERY = gql`
  query FindVehicleByRegistrationNumber($search: String!) {
    findVehicleByRegistrationNumber(search: $search) {
        id
        organizationId
        make
        model
        vehicleNumber
        registeredNumber
        seats
        vehicleClassId
        vehicleClass {
            id
            name
        }
    }
}
`;

// eslint-disable-next-line max-len
export const useFindVehicleByRegistrationNumberQuery = (search: string): QueryResult<FindVehicleByRegistrationNumberQuery, FindVehicleByRegistrationNumberQueryVariables> =>
  useQueryErrorNotification<FindVehicleByRegistrationNumberQuery, FindVehicleByRegistrationNumberQueryVariables>(FIND_VEHICLE_REGISTRATION_NUMBER_QUERY, {
    variables: { search },
  });

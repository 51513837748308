/* eslint-disable max-len */
import { gql, QueryResult } from '@apollo/client';
import { useQueryErrorNotification } from 'src/graphql/hooks/useQueryErrorNotification';
import { GetDiscountByIdQuery, GetDiscountByIdQueryVariables } from 'src/graphql/queries/__generated__/get-discount-by-id.generated';

const GET_DISCOUNT_BY_ID_QUERY = gql`
  query GetDiscountById($getDiscountByIdId: ID!) {
    getDiscountById(id: $getDiscountByIdId) {
      type
      amount
      startDate
      endDate
      code
      description
      isActive
      isOffline
      isOnline
    }
  }
`;

export const useGetDiscountByIdQuery = (
  id: string,
): QueryResult<GetDiscountByIdQuery, GetDiscountByIdQueryVariables> => {
  const variables = {
    getDiscountByIdId: id,
  };

  return useQueryErrorNotification<GetDiscountByIdQuery, GetDiscountByIdQueryVariables>(GET_DISCOUNT_BY_ID_QUERY, {
    variables,
  });
};

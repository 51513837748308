/* eslint-disable max-len */
import { gql, QueryResult } from '@apollo/client';
import { useQueryErrorNotification } from 'src/graphql/hooks/useQueryErrorNotification';
import { FindDiscountsQuery, FindDiscountsQueryVariables } from 'src/graphql/queries/__generated__/get-all-discounts.generated';
import { FindDiscountQueryInput } from 'src/graphql/generated/types';

const GET_DISCOUNTS_QUERY = gql`
  query FindDiscounts($pagination: PaginateQueryInput!, $input: FindDiscountQueryInput!) {
    findDiscounts(pagination: $pagination, input: $input) {
      meta {
        page
        offset
        totalItems
        totalPages
        itemCount
      }
      items {
        id
        type
        amount
        startDate
        endDate
        code
        description
        isActive
        isOffline
        isOnline
        createdAt
        updatedAt
      }
    }
  }
`;

export const useGetDiscountsQuery = (
  page: number,
  limit: number,
  offset?: number,
  searchType?: keyof FindDiscountQueryInput,
  searchField?: string | boolean,
): QueryResult<FindDiscountsQuery, FindDiscountsQueryVariables> => {
  const input = searchType && searchField ? { [searchType]: searchField } : {};

  const variables = {
    pagination: {
      page,
      limit,
      offset,
    },
    input,
  };

  return useQueryErrorNotification<FindDiscountsQuery, FindDiscountsQueryVariables>(GET_DISCOUNTS_QUERY, {
    variables,
  });
};

export type Discount = FindDiscountsQuery['findDiscounts']['items'][number];

import { Routes, Route } from 'react-router-dom';

import { AdminLayout } from 'src/components/admin-layout/AdminLayout';
import { AuthProvider } from 'src/providers/auth-provider/AuthProvider';
import { Users } from 'src/screens/users/UserManagement';
import { RoutesManagementScreen } from 'src/screens/routes-management/RoutesManagement';
import { VehicleScreen } from 'src/screens/vehicles/VehicleManagement';
import { RoutesFareManagement } from 'src/screens/routes-fare/RoutesFare';
import { RouteGroup } from 'src/screens/route-group/RouteGroup';
import { TravelSchedule } from 'src/screens/travel-schedule/TravelSchedule';
import { Page } from 'src/components/page/Page';
import { VehicleClass } from 'src/screens/vehicle-class/VehicleClass';
import { TripConfiguration } from 'src/screens/trip-configuration/TripConfiguration';
import { TicketingRoutes } from 'src/routes/TicketingRoutes';
import { TransactionsScreen } from 'src/screens/transactions/TransactionsPage';
import { Dashboard } from 'src/screens/dashboard/Dashboard';
import { BranchProvider } from 'src/providers/branch-provider/BranchProvider';
import { Trips } from 'src/screens/trips/Trips';
import { TripDetails } from 'src/screens/trips/TripDetails';
import { WaybillDetails } from 'src/screens/waybill/waybill-detail/WaybillDetails';
import { WaybillScreen } from 'src/screens/waybill/waybill-page/WaybillPage';
import { Passengers } from 'src/screens/passengers/Passengers';
import { Expenses } from 'src/screens/expenses/Expenses';
import { PassengerDetails } from 'src/screens/passenger-details/PassengerDetails';
import { TripManifest } from 'src/screens/trips/components/TripManifest';
import { PrintTicket } from 'src/screens/tickets/PrintTicket';
import { PrintWaybill } from 'src/screens/waybill/PrintWaybill';
import { PrintClosingPaper } from 'src/screens/closing-document/PrintClosingDoc';
import { ClosingBook } from 'src/screens/closing-document/ClosingBook';
import { Destinations } from 'src/screens/destinations/Destinations';
import { Reservations } from 'src/screens/reservations/Reservations';
import { ReservationDetails } from 'src/screens/reservation-details/ReservationDetails';
import { UserDetails } from 'src/screens/users/user-details/UserDetails';
import { VehicleClassConfiguration } from 'src/screens/vehicle-class/VehicleClassConfiguration';
import { RoutesFareOverride } from 'src/screens/routes-fare/RoutesFareOverrides';
import { Discounts } from 'src/screens/discounts/DiscountsManagement';
import { DiscountDetails } from 'src/screens/discounts/discount-details/DiscountDetails';
import { TransactionDetails } from 'src/screens/transactions/transaction-details/TransactionDetails';

export const AuthRoutes = () => {
  return (
    <AuthProvider>
      <BranchProvider>
        <Routes>
          <Route path="/" element={<AdminLayout />}>
            {/* Register all other auth routes within this rounte */}

            {/* Dashboard */}
            <Route path="/dashboard" element={<Page title="Dashboard" />}>
              <Route index element={<Dashboard />} />
            </Route>

            {/* User management */}
            <Route path="/users" element={<Page title="Users" />}>
              <Route index element={<Users />} />
              <Route path="/users/:id" element={<UserDetails />} />
            </Route>

            {/* Discounts management */}
            <Route path="/discounts" element={<Page title="Discounts" />}>
              <Route index element={<Discounts />} />
              <Route path="/discounts/:id" element={<DiscountDetails />} />
            </Route>

            {/* Bus routes */}
            <Route path="/vehicles" element={<Page title="Vehicles" />}>
              <Route index element={<VehicleScreen />} />
              <Route path="class" element={<VehicleClass />} />
              <Route path="class-configuration" element={<VehicleClassConfiguration />} />
            </Route>

            {/* Route routes */}
            <Route path="/routes" element={<Page title="Routes" />}>
              <Route index element={<RoutesManagementScreen />} />
              <Route path="fare" element={<RoutesFareManagement />} />
              <Route path="fare-overrides" element={<RoutesFareOverride />} />
              <Route path="groups" element={<RouteGroup />} />
            </Route>

            {/* Trip Routes */}
            <Route path="/trips" element={<Page title="Travel" />}>
              <Route index element={<Trips />} />
              <Route path=":id" element={<TripDetails />} />
              <Route path="configuration" element={<TripConfiguration />} />
            </Route>

            {/* Travel Routes */}
            <Route path="/travel" element={<Page title="Travel" />}>
              <Route path="schedule" element={<TravelSchedule />} />
            </Route>

            {/* Transaction Routes */}
            <Route path="/transactions" element={<Page title="Transactions" />}>
              <Route index element={<TransactionsScreen />} />
              <Route path="/transactions/:id" element={<TransactionDetails />} />
            </Route>

            {/* Expenses Routes */}
            <Route path="/expenses" element={<Page title="Expenses" />}>
              <Route index element={<Expenses />} />
            </Route>

            {/* Destination Routes */}
            <Route path="/destinations" element={<Page title="Destinations" />}>
              <Route index element={<Destinations />} />
            </Route>

            {/* Reservations Routes */}
            <Route path="/reservations" element={<Page title="All Reservations" />}>
              <Route index element={<Reservations />} />
              <Route path="/reservations/:id" element={<ReservationDetails />} />
            </Route>

            {/* Ticketing Routes */}
            <Route path="/ticketing/*" element={<TicketingRoutes />} />

            {/* Waybill Routes */}
            <Route path="/waybills" element={<Page title="Waybills" />}>
              <Route index element={<WaybillScreen />} />
              <Route path="/waybills/:id" element={<WaybillDetails />} />
            </Route>

            <Route path="passengers" element={<Page title="Passengers" />}>
              <Route index element={<Passengers />} />
              <Route path="/passengers/:id" element={<PassengerDetails />} />
            </Route>
          </Route>
          <Route path="/trip/:id/manifest" element={<TripManifest />} />
          <Route path="/trips/:id/closing-book" element={<ClosingBook />} />
          <Route path="/ticket/:id/print" element={<PrintTicket />} />
          <Route path="/waybill/:id/print" element={<PrintWaybill />} />
          <Route path="/trips/:id/printclosingpaper" element={<PrintClosingPaper />} />
        </Routes>
      </BranchProvider>
    </AuthProvider>
  );
};

/* eslint-disable max-len */
import { gql, QueryResult } from '@apollo/client';
import { useQueryErrorNotification } from 'src/graphql/hooks/useQueryErrorNotification';
import { FindUsersQuery, FindUsersQueryVariables } from 'src/graphql/queries/__generated__/find-users.generated';

const FIND_USERS = gql`
  query FindUsers($search: FindUsersInput!) {
  findUsers(search: $search) {
    id
    firstName
    lastName
    phone
    email
    roles
    permissions
    testUser
  }
}
`;

export const useFindUsersQuery = (search: string): QueryResult<FindUsersQuery, FindUsersQueryVariables> =>
  useQueryErrorNotification<FindUsersQuery, FindUsersQueryVariables>(FIND_USERS, {
    variables: { search: { search } },
  });

export type User = FindUsersQuery['findUsers'][number];

/* eslint-disable max-len */
import { MutationTuple, gql } from '@apollo/client';
import { useMutationErrorNotification } from 'src/graphql/hooks/useMutationErrorNotification';
import {
  UpdateDiscountMutation,
  UpdateDiscountMutationVariables,
} from 'src/graphql/mutations/__generated__/update-discount.generated';

export type IUpdateDiscount = UpdateDiscountMutation['updateDiscount'];

const UPDATE_DISCOUNT_MUTATION = gql`
  mutation UpdateDiscount($discountId: String!, $input: UpdateDiscountInput!) {
    updateDiscount(discountId: $discountId, input: $input) {
        id
        type
        amount
        startDate
        endDate
        code
        description
        isActive
        isOffline
        isOnline
        createdAt
        updatedAt
    }
  }
`;

export const useUpdateDiscountMutation = (): MutationTuple<UpdateDiscountMutation, UpdateDiscountMutationVariables> =>
  useMutationErrorNotification<UpdateDiscountMutation, UpdateDiscountMutationVariables>(UPDATE_DISCOUNT_MUTATION);
